import React, { createContext, useState, useContext } from "react";

const SimuladorContext = createContext();

export const SimuladorProvider = ({ children }) => {
  const [mostrarSimulador, setMostrarSimulador] = useState(false);

  return (
    <SimuladorContext.Provider
      value={{ mostrarSimulador, setMostrarSimulador }}
    >
      {children}
    </SimuladorContext.Provider>
  );
};

export const useSimulador = () => useContext(SimuladorContext);
