exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cumplimiento-js": () => import("./../../../src/pages/cumplimiento.js" /* webpackChunkName: "component---src-pages-cumplimiento-js" */),
  "component---src-pages-factoinvest-js": () => import("./../../../src/pages/factoinvest.js" /* webpackChunkName: "component---src-pages-factoinvest-js" */),
  "component---src-pages-factoring-js": () => import("./../../../src/pages/factoring.js" /* webpackChunkName: "component---src-pages-factoring-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-politicas-de-privacidad-js": () => import("./../../../src/pages/politicas-de-privacidad.js" /* webpackChunkName: "component---src-pages-politicas-de-privacidad-js" */),
  "component---src-templates-blog-detail-index-js": () => import("./../../../src/templates/blog-detail/index.js" /* webpackChunkName: "component---src-templates-blog-detail-index-js" */)
}

